import React, { Fragment } from 'react';
import { Alert, Divider, Modal, Typography } from "antd";
import { StarFilled } from '@ant-design/icons';
import { StatusCode } from "../App";

const { Text, Link } = Typography;

const CodeModal = (props: {
    modalVisible: boolean;
    setModalVisible: any;
    selectedCode: StatusCode | undefined
}) => {
    const selectedCode = props.selectedCode;
    const modalVisible = props.modalVisible;
    const setModalVisible = props.setModalVisible;

    const html = document.getElementsByTagName("html")[0];

    const removeOverflow = () => {
        html.classList.remove("no-scroll");
    }

    return (
        <Modal
            title={selectedCode?.title}
            centered
            cancelButtonProps={{ style: { display: 'none' } }}
            width={800}
            visible={modalVisible}
            onOk={() => {
                setModalVisible(false);
                removeOverflow();
            }}
            onCancel={() => {
                setModalVisible(false);
                removeOverflow();
            }}>
            {selectedCode && selectedCode?.desc && <div dangerouslySetInnerHTML={{ __html: selectedCode.desc }}/>}
            {selectedCode && selectedCode.source?.title &&
            <div><Text mark><strong>Source:</strong></Text> <Link href={selectedCode.source.link} target="_blank">{selectedCode.source.title}</Link></div>
            }
            {selectedCode && (selectedCode.desc || selectedCode.source?.title) && selectedCode.wikipedia_desc && <Divider/>}
            {selectedCode && selectedCode?.wikipedia_desc &&
            <Fragment>
               <Alert message="Wikipedia:" description={<div dangerouslySetInnerHTML={{ __html: selectedCode.wikipedia_desc }}/>} type="info"/>
            </Fragment>}
            {selectedCode && selectedCode.starred && <Alert className="starred-note" message={<p><StarFilled/> {selectedCode.more_info}</p>} type="warning"/>}
        </Modal>
    )
}

export default CodeModal;
