import React, { Fragment, useState } from 'react';
import Data from "../status-codes.json";
import { Collapse, Row, Col, Divider, Typography, Alert } from 'antd';
import { StarFilled } from '@ant-design/icons';

import CodeModal from "../components/Modal";
import { StatusCode } from "../App";

const { Panel } = Collapse;
const { Paragraph, Text, Title, Link } = Typography;

const Homepage = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedCode, setSelectedCode] = useState<StatusCode | undefined>(undefined);
    const html = document.getElementsByTagName("html")[0];

    const openModal = (e: React.MouseEvent<HTMLAnchorElement>, code: StatusCode) => {
        e.preventDefault();
        setModalVisible(true);
        setSelectedCode(code);
        html.classList.add("no-scroll");
    }

    return (
        <Fragment>
            {Data.map(category =>
                <Fragment key={category.category.slice(0, 7)}>
                    <Collapse bordered={false}>
                        <Panel header={<Title level={4}><Text keyboard>{category.category.slice(0, 7)}</Text> {category.category.slice(8)}</Title>} key="1" showArrow={false}>
                            <div dangerouslySetInnerHTML={{ __html: category.desc }}/>
                            {category.wikipedia_desc && <Fragment>
                               <Divider/>
                               <Alert message="Wikipedia:" description={<div dangerouslySetInnerHTML={{ __html: category.wikipedia_desc }}/>} type="info"/>
                            </Fragment>}
                        </Panel>
                    </Collapse>
                    <Row>
                        {category.status_codes.map(code => (
                            <Col xs={24} lg={12} xxl={8} key={code.code}>
                                <Paragraph copyable={{ text: code.code }}><Text code>{code.title.slice(0, 3)}</Text> {code.starred && <StarFilled/>} <Link onClick={e => openModal(e, code)} href="#">{code.title.slice(4)}</Link></Paragraph>
                            </Col>
                        ))}
                    </Row>
                    <Divider/>
                </Fragment>
            )}

            <Paragraph>
                <Text keyboard><StarFilled/> Top 10</Text> <Text type="secondary">HTTP Status Codes. More REST service-specific information is contained in the details modal of each status code.</Text>
            </Paragraph>

            <CodeModal selectedCode={selectedCode} modalVisible={modalVisible} setModalVisible={setModalVisible}/>
        </Fragment>
    )
}

export default Homepage;
