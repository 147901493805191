import React from 'react';
import './App.scss';
import 'antd/dist/antd.css';
import { Layout, Menu, Typography } from 'antd';
import { LinkOutlined, UnorderedListOutlined } from '@ant-design/icons';
import Homepage from "./pages/Homepage";
import { CollapseType } from "antd/lib/layout/Sider";
import ReactGA from 'react-ga';

const { Header, Sider, Content, Footer } = Layout;
const { Text, Link } = Typography;

function App() {
    const html = document.getElementsByTagName("html")[0];

    ReactGA.initialize('UA-5043424-8');
    ReactGA.pageview(window.location.pathname + window.location.search);

    const setOverflow = (collapsed: boolean, type: CollapseType) => {

        if ( !collapsed && type === "clickTrigger") {
            html.classList.add("no-scroll");
        } else {
            html.classList.remove("no-scroll");
        }
    }

    const removeOverflow = (broken: boolean) => {
        if(!broken) {
            html.classList.remove("no-scroll");
        }
    }

    return (
        <Layout id="components-layout">
            <Sider breakpoint="xl"
                   collapsedWidth="0"
                   onCollapse={(collapsed, type) => {setOverflow(collapsed, type);}}
                   onBreakpoint={broken => {removeOverflow(broken);}}>
                <div className="logo">
                    <span>HTTP Status Codes</span>
                </div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                    <Menu.Item key="1" icon={<UnorderedListOutlined/>}>Status Codes</Menu.Item>
                    <Menu.Item key="2" icon={<LinkOutlined/>}>HTTP Methods</Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout__header">Welcome to the <Text code>HTTP status codes</Text> directory</Header>
                <Content className="site-layout__content">
                    <Homepage/>
                </Content>
                <Footer style={{ textAlign: 'center' }}>Made with <span role="img" aria-label="Love">❤️</span> by <Link href="https://www.linkedin.com/in/elshahat" target="_blank">Ahmed ElShahat</Link></Footer>
            </Layout>
        </Layout>
    );
}

export default App;

export interface StatusCode {
    code: string,
    title: string,
    desc: string,
    source: {
        title: string,
        link: string
    }
    wikipedia_desc: string,
    more_info: string,
    starred: boolean
}
